<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Liste formulaire"
      class="px-5 py-3"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              Date
            </th>
            <th class="primary--text">
              Nom
            </th>
            <th class="primary--text">
              Prenom
            </th>
            <th class="primary--text">
              Sujet
            </th>
            <th class="primary--text">
              Mail
            </th>
            <th class="primary--text">
              Telephone
            </th>
            <th class="primary--text" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in pageOfItems"
            :key="item.numero"
          >
            <td> {{ item.date }}</td>
            <td> {{ item.nom }}</td>
            <td> {{ item.prenom }} </td>
            <td> {{ item.objet }} </td>
            <td> {{ item.mail }} </td>
            <td> {{ item.telephone }} </td>
            <td>
              <v-btn
                small
                color="edit"
                @click="voirMessageContact(item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Voir
              </v-btn>
            </td>
          </tr>
        </tbody>
        <!-- <tbody v-else>
          Il n'y a pas de commande pour ce filtre
        </tbody> -->
        <!--   <tr
            v-for="element in test"
            :key="element.id"
          >
            <td>
              <v-btn
                @click="GoDetailsCommande(item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Editer
              </v-btn>
            </td>
            <td> {{ element.id_commande }} </td>
            <td> {{ element.numero }} </td>
            <td> {{ element.date_creation }} </td>
            <td> {{ element.nom }} </td>
            <td> {{ element.statut }} </td>
            <td> {{ element.montant }} </td>
          </tr>
        </tbody> -->
      </v-simple-table>
    </base-material-card>

    <jw-pagination
      :items="items"
      @changePage="onChangePage"
    />
    <div class="py-3" />
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import JsonExcel from 'vue-json-excel'

  Vue.use(VueAxios, axios)
  Vue.component('downloadExcel', JsonExcel)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      // expandOnHover: {
      //   type: Boolean,
      //   default: false,
      // },
    },
    data: () => ({
      websiteid: '',
      pageOfItems: [],
      items: [],
      fileNameExport: 'filename.xls',
      variable: false,
      orderKey: '',
      rechercheNom: null,
      recherche: false,
      test: [],
      statutChoisi: '',
    }),
    computed: {
      evenItems: function () {
        return this.items.filter(function (item) {
          return item.id_commande % 2 === 0
        })
      },
    },
    mounted () {
      this.websiteid = this.$cookie.get('website_id')
      Vue.axios.get('https://api.espace-jantes.com/getAllFormulaire', { params: { websiteid: this.websiteid } }).then((response) => {
        response.data.forEach(element => {
          element.date = element.date.split('T')[0]
          var elementSplit = element.date.split('-')
          element.date = elementSplit[2] + '/' + elementSplit[1] + '/' + elementSplit[0]
          this.items.push(element)
        })
      })
    },
    methods: {
      onChangePage (pageOfItems) {
        // update page of items
        this.pageOfItems = pageOfItems
      },
      voirMessageContact: function (item) {
        this.$router.push({ name: 'FormulaireDetails', params: { infoFormulaire: item } })
      },
      rechercheCommande: function (rechercheNom) {
        console.log(rechercheNom)
        this.items.forEach(item => {
          if (item.numero === rechercheNom || item.nom === rechercheNom) {
            this.test.push(item)
          }
        })
        this.items = []
        this.items = this.test
      },
      supprimerNom: function (rechercheNom) {
        this.recherche = false
        this.test = []
      },
      onChange: function () {
      },
    },
  }
</script>
<style scoped>
  input[type=text] {
    border: 2px solid black !important;
    border-radius : 4px;
    margin-right : 20px;
  }
</style>
